import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var bnrimg = require('./../../images/banner/4.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            phoneNumber:'',
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        fetch('https://crmapi.bigboymachines.com/api/BBMSite/SendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                message: this.state.message,
                phoneNumber: this.state.phoneNumber,
            }),
        })
            .then(data => {
                console.log('Got success!', data);
                // Reset the form
                form.reset();
                // Clear the state values
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    phoneNumber: '',
                });
                toast.success('Mail Successfully Sent', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            })
            .catch(error => {
                console.error('The error is:', error);
                // Handle errors
            });
    }



    render() {
        const defaultProps = {
            center: {
            lat:18.953920,
            lng:72.841790
            },
            zoom: 12
        };
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Putting a plan to action, to assure your satisfaction!" pagename="Contact Us" bgimage={bnrimg}/>
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding" id='45'>
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row" >
                                    <div className="col-md-8 col-sm-6">
                                        <form className="contact-form cons-contact-form" onSubmit={this.handleSubmit} >
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head" >
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="form-group">
                                                    <input name="username" type="text"  onChange={event => {this.setState({name : event.target.value})}} required className="form-control" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="email" required="True" onInvalid={event => event.target.setCustomValidity('Please enter Correct Email Address')}
                                                           onInput={event => event.target.setCustomValidity('')}
                                                            onChange={event => {this.setState({email : event.target.value})}} className="form-control"   placeholder="Email"  />
                                                </div>
                                                <div className="form-group">
                                                    <input name="phone" type="text"  onChange={event => {this.setState({phoneNumber : event.target.value})}} required className="form-control" placeholder="Phone" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" onChange={event => {this.setState({message : event.target.value})}} rows={4} className="form-control " required placeholder="Message" defaultValue={""} />
                                                </div>


                                                <div className="text-right">
                                                    <button type="submit" className="site-button btn-effect">submit
                  </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(+91) 773-7066-666
</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>biz@bigboymachines.com</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Mumbai</h5>
                                                        <p>Office no 528, Vyapar Bhavan,<br/> 49, P D Mello Road, Masjid East <br/>Mumbai 400009</p>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Jaipur</h5>
                                                        <p>E5 Nirman Nagar E DCM<br/>Jaipur 302019</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gmap-outline">
                                <div style={{ height: '400px', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyCihdKOwFXNlTkFsFjNmgnLVE8BPfTQfZ0" }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                    >
                                        <AnyReactComponent
                                        lat={18.953920}
                                        lng={72.841790}
                                        text={<i className="fa fa-map-marker" />}
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <ToastContainer />
                <Footer />

            </>
        );
    };
};

export default ContactUs;