import React from 'react';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var img1 = require('./../../images/video-bg.jpg');

class OurValue extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    chnageModal() {



        const changeModalStyling = () => {
            console.log("modalwerwre")

            let modal = document.querySelector(".values-yt-video");
            console.log("modal", modal)
            if (modal && modal.firstChild) {
                modal.firstChild.style.width = "100%";
            }

        }

        changeModalStyling();
    }

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 bg-white">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Value</h2>
                                            </div>
                                            <p>Our values center around providing exceptional service,
                                                delivering quality cranes, and building lasting relationships with our clients.
                                            </p>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info p-t20">
                                        <div className="author-signature">
                                            <img src={require('./../../images/Signature+Black.png')} alt="Signature" width={150} />
                                        </div>
                                        <div className="author-name">
                                            <h4 className="m-t0">Rajesh Saini </h4>
                                            <p> Co Founder  &amp; CEO</p>
                                        </div>
                                    </div>
                                {/*    <div className="video-section-full bg-no-repeat bg-cover col-xxl-12 overlay-wraper pop-up-on-modal-content" style={{ backgroundImage: 'url(' + img1 + ')' }}>*/}

                                {/*        /!*<iframe name={"myModal2"} src="https://www.youtube.com/embed/tvMOLisurl0"  height="100%" width="100%"  allowFullScreen={"True"} webkitallowfullscreen="true" mozallowfullscreen="true" title="description"></iframe>*!/*/}
                                {/*        <img src="http://img.youtube.com/vi/tvMOLisurl0/maxresdefault.jpg" alt="Thumbnail"  />*/}

                                {/*        <div className="overlay-main bg-black opacity-07" />*/}
                                {/*        <div className="video-section-inner">*/}

                                {/*            <div className="video-section-content">*/}

                                {/*                <div className="video-section-left video-section-center">*/}
                                {/*                    <button onClick={this.chnageModal} type="button" className="play-now" data-toggle="modal" data-target="#myModal2">*/}

                                {/*                        <i className="icon fa fa-play" />*/}
                                {/*                        <span className="ripple" />*/}
                                {/*                    </button>*/}
                                {/*                </div>*/}
                                {/*                /!*<div className="video-section-right">*!/*/}
                                {/*                /!*<NavLink to={"#"} className="font-weight-600 text-uppercase" data-toggle="modal" data-target="#myModal">PLay Video</NavLink>*!/*/}
                                {/*                /!*</div>*!/*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={200}  suffix="+" duration={3} /></div>
                                        <h4 className="m-tb0">Cranes Delivered</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15  text-primary"><CountUp end={850} suffix="+" duration={3} /></div>
                                        <h4 className="m-tb0">Satisfied Clients</h4>
                                    </div>
                                    <div className="mt-count m-b30 text-white mt-icon-box-wraper center  bg-dark p-a20">
                                        <div className="counter font-30 font-weight-800 m-b15 text-primary"><CountUp end={500} suffix="+" duration={3} /></div>
                                        <h4 className="m-tb0">Projects Completed</h4>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    <div className="p-b0">
                                        <div className="mt-box">
                                            <h3 className="m-t0"><span className="font-weight-100"> We have</span><span className="text-primary"> 15 years</span> experience in Industry</h3>
                                        </div>
                                        <span className="progressText text-black"><b>Road & Railway</b></span>
                                        <div className="progress mt-probar-1  m-b30 m-t10">
                                            <div className="progress-bar bg-primary " role="progressbar" aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="85%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Construction</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={78} aria-valuemin={10} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="78%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Refinery</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="65%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Mining</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={40} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="40%" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Commercial</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={55} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title="55%" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade pop-up-for-phone" id="myModal2" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content ">
                            <div className="pop-up-on-modal-content values-yt-video">
                                <ReactPlayer  url='https://www.youtube.com/embed/tvMOLisurl0' />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
};

export default OurValue;
